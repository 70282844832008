<template>
    <div id="home-procedure-roadmap-dom" class="home-procedure-roadmap">
        <div class="home-procedure-roadmap-title">
            {{ $t(roadmapTitle) }}
        </div>
        <div ref="scrollerEl" class="scroller-el">
            <div class="roadmap">
                <svg id="star-svg" ref="svg" viewBox="0 0 4200 810" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="view-box">
                        <!-- 画点 -->
                        <!-- <circle class="star-point" cx="40" cy="40" r="10" stroke="white" fill="white"
                                    stroke-width="3" /> -->
                        <!-- 整体路径线条 -->
                        <path
                            id="star-path"
                            d="M 50 22
                                C 448 263 579 342 919 555
                                C 880 452 1417 220 1736 255
                                C 1606 310 2423 454 2493 555
                                C 2447 470 3077 228 3291 255
                                C 3176 324 4114 451 4200 600"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke="white"
                            stroke-width="4"
                        />
                        <!-- 对路径分段，无笔迹 -->
                        <path id="star-path-1" d="M 50 22C 448 263 579 342 919 555" />
                        <path id="star-path-2" d="M 919 555 C 880 452 1417 220 1736 255" />
                        <path id="star-path-3" d="M 1736 255 C 1606 310 2423 454 2493 555" />
                        <path id="star-path-4" d="M 2493 555 C 2447 470 3077 228 3291 255" />
                        <path id="star-path-4" d="M 3291 255 C 3176 324 4114 451 4200 5505" />
                    </g>
                </svg>
                <div class="roadmap-item-container">
                    <div v-for="(roadmapItem, itemIndex) in roadmapData" :key="itemIndex" class="roadmap-item">
                        <div>
                            <div>{{ $t(roadmapItem.title) }}</div>
                            <div class="roadmap-subtext">{{ $t(roadmapItem.subtitle) }}</div>
                        </div>
                        <div v-if="roadmapItem.mediaPath" class="roadmap-item-media">
                            <CommonComponentsVideoPlayer
                                :id="`home-procedure-roadmap-video-${itemIndex}`"
                                class="home-procedure-roadmap-video"
                                :sources="[
                                    {
                                        src: roadmapItem.mediaPath,
                                        type: 'video/mp4',
                                    },
                                ]"
                                :poster="roadmapItem.poster"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    gsap.registerPlugin(ScrollTrigger);

    defineProps<{
        roadmapTitle: string;
        roadmapData: Array<{
            title: string;
            subtitle: string;
            mediaPath?: string;
            poster?: string;
        }>;
    }>();

    const scrollerEl = ref(null);
    const svg = ref(null);

    interface PointItem {
        roadmapItem: HTMLElement;
        videoItem: HTMLVideoElement;
        appearPercentage: number;
    }

    const showItem = (pointItem: PointItem) => {
        gsap.to([pointItem.roadmapItem], {
            opacity: 1,
        });
        if (pointItem.videoItem && pointItem.videoItem.paused) {
            pointItem.videoItem.play();
        }
    };

    const hideItem = (pointItem: PointItem) => {
        gsap.to([pointItem.roadmapItem], {
            opacity: 0,
        });
        if (pointItem.videoItem && pointItem.videoItem.paused) {
            pointItem.videoItem.pause();
        }
    };

    const getPointController = (pathLength: number): PointItem[] => {
        // <path> path的段数，绘制端点
        let beforeLength = 0;
        // const pointArray = gsap.utils.toArray('.star-point'); // Point Dom except the first point
        const videoArray = gsap.utils.toArray('.home-procedure-roadmap-video'); // Point Dom except the first point
        const roadmapItemArray = gsap.utils.toArray('.roadmap-item'); // Roadmap Item 与点相对应
        // 计算端点出现的时机
        return Array.from({ length: roadmapItemArray.length }, (_, i) => i).map((index) => {
            const pathItem = document.querySelector(`#star-path-${index + 1}`) as SVGPathElement;
            const curlength = pathItem.getTotalLength() + beforeLength;
            beforeLength = curlength;
            return {
                roadmapItem: roadmapItemArray[index] as HTMLElement,
                videoItem: videoArray[index] as HTMLVideoElement,
                // point: pointArray[index] as HTMLElement,
                appearPercentage: curlength / pathLength,
            };
        });
    };

    // 随着线条进度 整个盒子向左偏移
    const moveRoadmapLeft = (progress: number) => {
        // const svg = document.querySelector('#star-svg') as HTMLElement;
        // const scrollerEl = document.querySelector('.scroller-el') as HTMLElement;
        if (!svg.value) return;
        const svgLength = svg.value.clientWidth;
        const clientWidth = document.documentElement.clientWidth || document.body.clientWidth;

        if (svgLength > clientWidth) {
            const offetLenth = svgLength - clientWidth;
            gsap.to(scrollerEl.value, {
                x: -offetLenth * progress,
            });
        }
    };

    // progress 换算公式
    const calcScrollPercentage = (progress: number) => {
        return progress * 1.25 - 0.25;
    };

    const pointProcess = (direction: number | 'init', scrollPercentage: number, pointItem: PointItem) => {
        const distance = scrollPercentage - pointItem.appearPercentage;
        switch (direction) {
            case 'init':
                if (distance > 0) {
                    showItem(pointItem);
                }
                break;
            case 1:
                if (Math.abs(distance) <= 0.02) {
                    // 滚动方向 向下
                    showItem(pointItem);
                }
                break;
            case -1:
                if (Math.abs(distance) <= 0.02) {
                    // 滚动方向 向上
                    hideItem(pointItem);
                }
                break;
            default:
                break;
        }
    };

    const flushState = (
        pathInfo: { path: SVGPathElement; pathLength: number },
        pointController: PointItem[],
        scrollPercentage: number,
        direction: number | 'init',
    ) => {
        const { path, pathLength } = pathInfo;
        moveRoadmapLeft(scrollPercentage);
        // 绘制路径
        const drawLength = pathLength * scrollPercentage;
        path.style.strokeDashoffset = `${pathLength - drawLength}`;

        // 到达路径转折点时出现内容
        pointController.forEach((pointItem) => {
            if (pointItem.roadmapItem) {
                pointProcess(direction, scrollPercentage, pointItem);
            }
        });
    };

    onMounted(() => {
        // 基于ScrollTrigger绘制 Procedure
        // Get a reference to the <path>
        const path = document.querySelector('#star-path') as SVGPathElement;
        const pathLength = path.getTotalLength();

        const containerEl = document.querySelector('#home-procedure-roadmap-dom') as HTMLElement;

        // Make very long dashes (the length of the path itself)
        path.style.strokeDasharray = pathLength + ' ' + pathLength;
        // Offset the dashes so the it appears hidden entirely
        path.style.strokeDashoffset = `${pathLength}`;

        const pointController: PointItem[] = getPointController(pathLength);

        ScrollTrigger.create({
            // markers: { startColor: 'green', endColor: 'red', fontSize: '12px' },
            trigger: containerEl,
            start: 'top bottom',
            end: '+=3000',
            onEnter: (self) => {
                const scrollPercentage = calcScrollPercentage(self.progress);
                flushState({ path, pathLength }, pointController, scrollPercentage, 'init');
            },
            onUpdate: (self) => {
                const scrollPercentage = calcScrollPercentage(self.progress);
                flushState({ path, pathLength }, pointController, scrollPercentage, self.direction);

                // When complete, remove the dash array, otherwise shape isn't quite sharp
                // Accounts for fuzzy math
                if (scrollPercentage >= 0.999) {
                    path.style.strokeDasharray = 'none';
                } else {
                    path.style.strokeDasharray = pathLength + ' ' + pathLength;
                }
            },
        });
        // 固定
        ScrollTrigger.create({
            trigger: containerEl,
            start: 'top top',
            end: '+=3000 bottom',
            pin: true,
            pinSpacing: true,
        });
        // viewboxOffset(containerEl);
    });
</script>

<style lang="less" scoped>
    .home-procedure-roadmap {
        height: 100vh;
        width: 100%;
        overflow: hidden;

        .home-procedure-roadmap-title {
            height: 10vh;
            display: flex;
            justify-content: center;
            align-items: center;

            font-size: .convert(36px) [ @vw];
        }

        .scroller-el {
            height: 90vh;
            width: -webkit-fit-content;
            width: -moz-fit-content;
            width: fit-content;
            display: flex;
            flex-wrap: nowrap;

            .roadmap {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: center;
                position: relative;

                #star-svg {
                    position: relative;
                    // width: .convert(1750px)[@vw];
                    // width: 100%;

                    height: 100%;

                    .star-point {
                        opacity: 0;
                    }
                }

                .roadmap-item {
                    position: absolute;
                    opacity: 0;
                    color: #ffffff;
                    font-size: .convert(@font-size-title-xs) [ @vw];
                    display: flex;
                    gap: .convert(24px) [ @vw];

                    .roadmap-subtext {
                        font-size: .convert(@font-size-lg) [ @vw];
                    }

                    .roadmap-item-media {
                        height: calc(255vh / 8.1);
                        width: auto;
                        video {
                            height: 100%;
                        }
                    }

                    &:nth-child(1) {
                        left: .convert(940px) [ @vh-nolimit];
                        bottom: .convert(20px) [ @vh-nolimit];
                    }

                    &:nth-child(2) {
                        left: .convert(1740px) [ @vh-nolimit];
                        top: .convert(10px) [ @vh-nolimit];
                    }

                    &:nth-child(3) {
                        left: .convert(2540px) [ @vh-nolimit];
                        bottom: .convert(20px) [ @vh-nolimit];
                    }

                    &:nth-child(4) {
                        left: .convert(3291px) [ @vh-nolimit];
                        top: .convert(10px) [ @vh-nolimit];
                    }
                }
            }
        }
    }

    :lang(en) {
        .roadmap-subtext {
            display: none;
        }
    }
</style>
