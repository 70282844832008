<template>
    <div class="svg-container">
        <svg class="svg-icon" viewBox="0 0 103 81" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M37.0648 0.499992L43.0648 7.69999C28.0648 16.1 20.2648 26 20.2648 36.5C20.2648 41.3 20.5648 41.6 29.8648 43.7C37.9648 45.8 43.3648 52.7 43.3648 61.1C43.3648 72.2 34.3648 80.6 22.9648 80.6C9.76485 80.6 0.164845 69.2 0.164845 53.6C0.164845 31.4 13.9648 11.3 37.0648 0.499992ZM95.8649 0.499992L101.865 7.69999C86.8649 16.1 79.0648 26 79.0648 36.5C79.0648 41.3 79.3649 41.6 88.6648 43.7C96.7649 45.8 102.165 52.7 102.165 61.1C102.165 72.2 93.1649 80.6 81.7648 80.6C68.5648 80.6 58.9648 69.2 58.9648 53.6C58.9648 31.4 72.7648 11.3 95.8649 0.499992Z"
                fill="white"
            />
        </svg>
    </div>
</template>
<style scoped>
    .svg-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
