<template>
    <div class="user-voice-scroll-cards" @touchend="end" @touchstart="start">
        <div class="user-voice-mobile-cards">
            <CommonComponentsHomeUserVoiceCard
                v-for="(userVoiceItem, itemIndex) in posedUserVoiceCardList"
                :key="itemIndex"
                class="user-voice-mobile-cards-item"
                :user-voice-item="userVoiceItem"
            />
        </div>
    </div>
</template>
<script lang="ts" setup>
    const props = defineProps<{
        userVoiceCardList: Array<SolutionCard>;
    }>();

    const posedUserVoiceCardList = computed(() => [
        props.userVoiceCardList[props.userVoiceCardList.length - 1],
        ...props.userVoiceCardList,
        ...props.userVoiceCardList.slice(0, 2),
    ]);
    const data = reactive({
        loading: true,
        currentIndex: 0,
        centerInfo: '',
        startX: '',
        endX: '',
        previous: 0,
        cardStyle: [],
    });
    // 滑动上一个
    const prev = () => {
        const container = document.querySelector('.user-voice-mobile-cards') as HTMLElement;
        // data.cardStyle.push(data.cardStyle.shift());
        data.currentIndex = data.currentIndex - 1;
        if (data.currentIndex < 0) {
            data.currentIndex = props.userVoiceCardList.length;
            container.style.transition = 'none';
            container.style.transform = data.cardStyle[data.cardStyle.length - 1].transform;
            setTimeout(() => prev(), 10);
        } else {
            container.style.transition = '.4s';
            container.style.transform = data.cardStyle[data.currentIndex].transform;
        }
    };
    // 滑动下一个
    const next = () => {
        const container = document.querySelector('.user-voice-mobile-cards') as HTMLElement;
        // data.cardStyle.unshift(data.cardStyle.pop());
        data.currentIndex = data.currentIndex + 1;
        if (data.currentIndex > props.userVoiceCardList.length) {
            data.currentIndex = 0;
            container.style.transition = 'none';
            container.style.transform = data.cardStyle[0].transform;
            setTimeout(() => next(), 10);
        } else {
            container.style.transition = '.4s';
            container.style.transform = data.cardStyle[data.currentIndex].transform;
        }
    };
    // 开始移动端滑动屏幕
    const start = (event) => {
        data.startX = event.changedTouches[0].clientX;
        data.startY = event.changedTouches[0].clientY;
    };
    // 连续滑动 @touchmove="move"
    const move = (event) => {
        // data.endY = event.changedTouches[0].clientY;
        // data.endX = event.changedTouches[0].clientX;
        // stopDefault(event);
        // data.interval = data.endX - data.startX;
        // if (data.interval > 40) {
        //     data.startX = data.endX;
        //     data.prev();
        // }
        // if (data.interval < -40) {
        //     data.startX = data.endX;
        //     data.next();
        // }
    };
    // 滑动
    const end = (event) => {
        data.endY = event.changedTouches[0].clientY;
        data.endX = event.changedTouches[0].clientX;
        formatSwiper();
    };
    const formatSwiper = () => {
        if (data.startX > data.endX + 40) {
            next();
        } else if (data.endX > data.startX + 40) {
            prev();
        }
    };
    // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
    // const stopDefault = (event) => {
    //     const differenceY = data.endY - data.startY;
    //     const differenceX = data.endX - data.startX;
    //     if (Math.abs(differenceX) > Math.abs(differenceY)) {
    //         event.preventDefault();
    //     }
    // };

    // const addCardStyle = () => {
    //     if (props.userVoiceCardList.length > 3) {
    //         const addtime = props.userVoiceCardList.length - 3;
    //         for (let i = 0; i < addtime; i++) {
    //             console.log('add');
    //             data.cardStyle.push({
    //                 opacity: 0,
    //                 transition: 'none',
    //             });
    //         }
    //     }
    // };

    const initCardStyle = () => {
        const length = props.userVoiceCardList.length;

        for (let i = 0; i < length + 1; i++) {
            const k = 1.5 + i;
            const per = (100 * k) / (length + 3);
            // console.log(k, length + 3);
            // console.log(per);
            data.cardStyle.push({
                transform: `translate(-${per}%)`,
                '-webkit-transform': `translate(-${per}%)`,
                '-moz-transform': `translate(-${per}%)`,
                '-ms-transform': `translate(-${per}%)`,
                '-o-transform': `translate(-${per}%)`,
                transition: '.4s',
            });
        }
    };

    onBeforeMount(() => {
        // addCardStyle(); // 加入样式位置的index
        initCardStyle();
    });
    onMounted(() => {
        (document.querySelector('.user-voice-mobile-cards') as HTMLElement).style.transform =
            data.cardStyle[0].transform;
    });
</script>

<style lang="less" scoped>
    .user-voice-scroll-cards {
        width: fit-content;
        .user-voice-mobile-cards {
            display: flex;
            position: absolute;
            left: 50%;

            &-item {
                margin: 0 5px;
                position: relative;
            }
        }
    }
</style>
