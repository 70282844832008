<template>
    <div class="home-swipper-container">
        <div class="global-limited-1360px-container home-swipper-text-container">
            <div class="home-swipper-content">
                <!-- <div class="home-swipper-content-svg">
                <CommonComponentsSVGCommonStyle3DWhite />
            </div> -->
                <!-- <div class="home-swipper-content-text" v-html="homoSwipperData.text" /> -->
                <img class="logo" :src="logoImg" />
                <div class="home-swipper-content-subtext" v-html="homoSwipperData.subtext" />
            </div>
        </div>

        <!-- <CommonComponentsVideoPlayer :sources="homoSwipperData.videoSources" :poster="homoSwipperData.videoPoster" /> -->
        <video :src="homeSwipperVideo" muted loop playsinline autoplay preload="auto"></video>
        <div class="home-swipper-mask"></div>
    </div>
</template>
<script lang="ts" setup>
    import homeSwipperVideo from '@assets/Video/home/first_screen.mp4';
    import logoEn from '@assets/Video/home/logoEn.png';
    import logoCn from '@assets/Video/home/logoCn.png';
    import { ref, reactive, onMounted } from 'vue';
    import i18n from '@/locales';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    const isChinese = ifCNLocale();

    const logoImg = isChinese ? logoCn : logoEn;

    const { t } = i18n.global;
    const isMobile = ref(false);

    const homoSwipperData = reactive({
        text: t('global_home_swipper_text'),
        subtext: t('global_home_swipper_subtext'),
        videoSources: [
            {
                src: '/Video/home/first_screen.mp4',
                type: 'video/mp4',
            },
        ],
        videoPoster: '/Video/home/first_screen_cover.jpg',
    });

    onMounted(() => {
        isMobile.value = matchMobile();
        if (isMobile.value) {
            // (document.querySelector('.home-swipper-content-text') as HTMLElement).innerHTML = t(
            //     'global_home_swipper_text_mobile',
            // );
            // (document.querySelector('.home-swipper-content-subtext') as HTMLElement).innerHTML = t(
            //     'global_home_swipper_subtext_mobile',
            // );
            homoSwipperData.text = t('global_home_swipper_text');
            homoSwipperData.subtext = t('global_home_swipper_subtext_mobile');
        }
        addSlideInUp('.home-swipper-content');
    });
</script>
<style lang="less" scoped>
    .home-swipper-container {
        position: relative;
        width: 100%;
        height: 100vh;
        color: #fff;
        overflow: hidden;

        &.hide {
            opacity: 0;
        }

        .logo {
            width: 584px;
            margin-left: -15px;
            @media (max-width: @screen-mobile-width) {
                width: 240px;
                margin-left: -5px;
            }
        }

        video {
            z-index: 0;
            position: absolute;
            top: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        .home-swipper-mask {
            z-index: 1;
            position: absolute;
            top: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
        }
        .home-swipper-text-container {
            z-index: 2;
            height: 100vh;
            position: relative;

            .home-swipper-content {
                font-family: 'HarmonyOS Sans SC';
                opacity: 0;
                position: absolute;
                left: 0;
                top: 42%;
                transform: translate(0, -50%);
                .home-swipper-content-svg {
                    .svg-styl3d-white {
                        width: .convert(442px) [ @vw-nolimit];
                        height: auto;
                    }
                }
                .home-swipper-content-text {
                    font-family: Montserrat;

                    color: #fff;
                    font-size: 64px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                    // text-transform: capitalize;
                    filter: drop-shadow(0 0 1rem #3a3a3a);

                    @media (max-width: @screen-mobile-width) {
                        font-size: .convert(48px) [ @vw-mobile];
                    }
                }
                .home-swipper-content-subtext {
                    margin-top: 24px;
                    color: #fff;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 160%; /* 32px */
                    filter: drop-shadow(0 0 1rem #3a3a3a);

                    @media (max-width: @screen-mobile-width) {
                        font-size: .convert(14px;) [ @vw-mobile];
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    :lang(en) {
        .home-swipper-container {
            .home-swipper-text-container {
                .home-swipper-content-text {
                    @media (max-width: @screen-mobile-width) {
                        font-size: .convert(43px) [ @vw-mobile];
                    }
                }
            }
        }
    }
</style>
