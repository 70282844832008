<template>
    <div class="home-solutions-layout">
        <div class="home-solutions-title-container">
            <CommonComponentsUnionTitle :union-title="unionTitle" />
        </div>
        <div class="horizontal-scroller">
            <div class="home-solutions-cards mobile">
                <CommonComponentsHomeSolutionsMobileCards :solutions-cards="solutionsCards" />
            </div>
            <div class="home-solutions-cards pc">
                <CommonComponentsHomeSolutionsCard
                    v-for="(solutionCard, itemIndex) in solutionsCards"
                    :key="itemIndex"
                    class="home-solutions-cards-item"
                    :solution-card="solutionCard"
                    :handle-click="() => pushRouter(solutionCard.path)"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { ref, onBeforeMount, onMounted } from 'vue';
    import { range } from 'ramda';
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import image1 from '@assets/imgs/Home/Solutions/image1.jpeg';
    import image1mobile from '@assets/imgs/Home/Solutions/image1mobile.jpeg';
    import image2 from '@assets/imgs/Home/Solutions/image2.jpeg';
    import image2mobile from '@assets/imgs/Home/Solutions/image2mobile.jpeg';
    import image3 from '@assets/imgs/Home/Solutions/image3.jpg';
    import image3mobile from '@assets/imgs/Home/Solutions/image3mobile.jpeg';
    import image4 from '@assets/imgs/Home/Solutions/image4.png';
    import image4mobile from '@assets/imgs/Home/Solutions/image4mobile.png';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import { routeMapper } from '~~/composables/useRoutesMapper';
    import i18n from '@/locales';
    const { t } = i18n.global;

    gsap.registerPlugin(ScrollTrigger);
    const isMobile = ref<boolean>(false);

    const unionTitle: UnionTitle = {
        title: 'global_home_section_title_0',
        subTitle: 'global_home_section_subtitle_0',
    };

    const solutionsCards: Array<SolutionCard> = [
        {
            itemName: 'global_home_solutions_name_0',
            itemDes: 'global_home_solutions_des_0',
            linkButtonText: t('global_link_button_0'),
            img: image1,
            imgMobile: image1mobile,
            isDark: false,
            path: routeMapper.solutions.brand,
        },
        {
            itemName: 'global_home_solutions_name_2',
            itemDes: 'global_home_solutions_des_2',
            linkButtonText: t('global_link_button_0'),
            img: image2,
            imgMobile: image2mobile,
            isDark: false,
            path: routeMapper.solutions.manufacturer,
        },
        {
            itemName: 'global_home_solutions_name_1',
            itemDes: 'global_home_solutions_des_1',
            linkButtonText: t('global_link_button_0'),
            img: image3,
            imgMobile: image3mobile,
            isDark: false,
            path: routeMapper.solutions.fabric,
        },
        {
            itemName: 'global_home_solutions_name_3',
            itemDes: 'global_home_solutions_des_3',
            linkButtonText: t('global_link_button_0'),
            img: image4,
            imgMobile: image4mobile,
            isDark: false,
            path: routeMapper.solutions.academy,
        },
    ];

    const { $bus } = useNuxtApp();
    const showModal = () => {
        $bus.$emit('show:applyModal');
    };

    const initCardStacking_PC = () => {
        const scrollHeight = 1000;
        const parent = '.home-solutions-layout';
        const cardContainer = '.home-solutions-cards.pc';
        const cardSelector = `${cardContainer} >  .home-solutions-cards-item`;
        const cardNodes = gsap.utils.toArray(cardSelector) as HTMLElement[];
        const count = cardNodes.length;
        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: parent,
                start: 'top top',
                end: `+=${scrollHeight * count}`,
                scrub: true,
                pin: true,
                pinSpacing: true,
                // snap: {
                //     snapTo: 1 / (count - 1),
                //     delay: 0,
                //     directional: true,
                //     duration: {
                //         min: 1,
                //         max: 2,
                //     },
                //     ease: 'power2.out',
                // },
            },
        });
        range(2, count + 1).forEach((i) => {
            tl.from(`${cardSelector}:nth-child(${i})`, {
                yPercent: 200,
                ease: 'slow(0.7, 0.7, false)',
                duration: 4,
            });
            const screenWidth = screen.width;
            // console.log('screenWidth', screenWidth);
            const { tx, ty, tz } = { tx: 0, ty: 68, tz: screenWidth < 1000 ? 200 : 100 }; // 简单地进行1000以上屏幕的适配

            range(i - 3 > 0 ? i - 3 : 1, i).forEach((j) => {
                const count = i - j;
                // console.log(i, j, count);
                tl.to(
                    `${cardSelector}:nth-child(${j})`,
                    {
                        transform: `translate3d(${tx * count}px,-${ty * count}px,  -${tz * count}px)`,
                        duration: 4,
                    },
                    '<',
                );
            });

            // 若卡片数量超过三张，则对往前数第三张进行隐藏
            if (i > 3) {
                tl.to(
                    `${cardSelector}:nth-child(${i - 3})`,
                    {
                        opacity: 0,
                        duration: 4,
                    },
                    '<',
                );
            }
        });
    };

    onBeforeMount(() => {
        isMobile.value = matchMobile();
    });
    // 滚动触发动画
    onMounted(() => {
        if (!isMobile.value) {
            initCardStacking_PC();
        } else {
            // horizontalDragger.init('.horizontal-scroller');
            // getMobileLoopTimeLine();
        }
    });
</script>

<style lang="less" scoped>
    .home-solutions-layout {
        // padding: .convert(50px) [ @vw-nolimit] 0;
        width: 100%;
        min-height: 100vh;
        padding: 20px 0 50px 0;
        // overflow: hidden;

        display: flex;
        flex-direction: column;
        justify-content: center;
        .home-solutions-title-container {
            width: 100%;
            margin-bottom: min(.convert(80px) [ @vw-nolimit], 150px);
        }

        .horizontal-scroller {
            .home-solutions-cards,
            :deep(.mobile-cards) {
                position: relative;
                margin: 0 auto;

                transform: perspective(1000px);
                transform-style: preserve-3d;

                &-item {
                    position: absolute;
                }
            }
            .home-solutions-cards {
                &.pc {
                    display: block;
                    height: .convert(688px) [ @vw];
                    width: .convert(1224px) [ @vw];
                }
                &.mobile {
                    display: none;
                    width: .convert(295px) [ @vw-mobile];
                    height: .convert(475px) [ @vw-mobile];
                }
            }
        }

        @media (max-width: @screen-mobile-width) {
            .home-solutions-title-container {
                margin-bottom: 30px;
            }

            .horizontal-scroller {
                .home-solutions-cards {
                    &.pc {
                        display: none;
                    }
                    &.mobile {
                        display: block;
                    }
                }
            }
        }

        :lang(en) {
            :deep(.union-subtitle) {
                display: none;
            }
        }
    }
</style>
