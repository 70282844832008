<template>
    <div class="home-procedure-layout">
        <div class="home-procedure-topic">
            <div class="home-procedure-image">
                <div class="home-procedure-svg-style3d">
                    <CommonComponentsSVGHomeProcedureStyle3D />
                </div>
                <!-- <img class="home-procedure-bg" src="/Home/home-image-1.png" /> -->
            </div>

            <!-- <div class="home-procedure-content-subtitle" v-html="$t(content.subtitle)" /> -->
        </div>
        <div class="home-procedure-roadmap-pc">
            <CommonComponentsHomeProcedureRoadMap :roadmap-title="content.title" :roadmap-data="content.roadmapData" />
        </div>
        <div class="home-procedure-roadmap-mobile">
            <CommonComponentsHomeProcedureRoadMapMobile
                :roadmap-title="content.title"
                :roadmap-data="content.roadmapData"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';

    import procedurePlaning from '@assets/Video/home/procedure_planing.mp4';
    import procedureDesign from '@assets/Video/home/procedure_design.mp4';
    import procedureProduce from '@assets/Video/home/procedure_produce.mp4';
    import procedureMarketing from '@assets/Video/home/procedure_marketing.mp4';
    import procedurePlaningPoster from '@assets/Video/home/procedure_planing.jpg';
    import procedureDesignPoster from '@assets/Video/home/procedure_design.jpg';
    import procedureProducePoster from '@assets/Video/home/procedure_produce.jpg';
    import procedureMarketingPoster from '@assets/Video/home/procedure_marketing.jpg';

    import { addSlideInUp } from '~~/composables/useScrollTrigger';
    const content = reactive({
        title: 'global_home_procedure_content_title',
        subtitle: 'global_home_procedure_content_subtitle',
        roadmapData: [
            {
                title: 'global_home_procedure_roadmap_title_0',
                subtitle: 'global_home_procedure_roadmap_subtitle_0',
                mediaPath: procedurePlaning,
                poster: procedurePlaningPoster,
            },
            {
                title: 'global_home_procedure_roadmap_title_1',
                subtitle: 'global_home_procedure_roadmap_subtitle_1',
                mediaPath: procedureDesign,
                poster: procedureDesignPoster,
            },
            {
                title: 'global_home_procedure_roadmap_title_2',
                subtitle: 'global_home_procedure_roadmap_subtitle_2',
                mediaPath: procedureProduce,
                poster: procedureProducePoster,
            },
            {
                title: 'global_home_procedure_roadmap_title_3',
                subtitle: 'global_home_procedure_roadmap_subtitle_3',
                mediaPath: procedureMarketing,
                poster: procedureMarketingPoster,
            },
        ],
    });
    gsap.registerPlugin(ScrollTrigger);

    onMounted(() => {
        // zoom in
        addSlideInUp('.home-procedure-svg-style3d');
        // addSlideInUp('.home-procedure-bg');
        // addSlideInUp('.home-procedure-content-title , .home-procedure-content-subtitle');
    });

    // onBeforeUnmount(() => {
    //     ScrollTrigger.killAll()
    // })
</script>

<style lang="less" scoped>
    .home-procedure-layout {
        color: #ffffff;
        margin-top: .convert(100px) [ @vw];
        font-family: Montserrat, 'HarmonyOS Sans SC';
        .home-procedure-topic {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .home-procedure-image {
                width: 100%;
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .home-procedure-svg-style3d {
                    // z-index: 1;
                    // position: absolute;
                    // top: 50%;
                    // transform: translate(0, -50%);

                    :deep(.svg-icon) {
                        width: .convert(736px) [ @vw];
                    }
                    @media (max-width: @screen-mobile-width) {
                        :deep(.svg-icon) {
                            width: .convert(304px) [ @vw-mobile];
                        }
                    }
                }

                img {
                    margin: 0 auto;
                    max-width: .convert(720px) [ @vw];
                }
            }

            // .home-procedure-content {
            //     &-title {
            //         font-size: .convert(@font-size-title) [ @vw];
            //         @media (max-width: @screen-mobile-width) {
            //             font-size: .convert(@font-size-xl) [ @vw-mobile];
            //             line-height: normal;
            //         }
            //     }

            //     &-subtitle {
            //         font-size: .convert(@font-size-xl) [ @vw];
            //         text-align: center;
            //         color: @color-main;
            //         font-weight: 700;
            //         line-height: normal;
            //         text-transform: capitalize;
            //         @media (max-width: @screen-mobile-width) {
            //             font-size: .convert(@font-size-xm-xm) [ @vw-mobile] !important;
            //         }
            //     }
            // }

            @media (max-width: @screen-mobile-width) {
                padding-bottom: 10px;
            }
        }

        .home-procedure-roadmap {
            &-pc {
                width: 100%;
                display: block !important;
                padding-bottom: 40px;
            }
            &-mobile {
                width: 100%;
                display: none !important;
                margin-bottom: 80px;
            }
            @media (max-width: @screen-mobile-width) {
                &-pc {
                    display: none !important;
                }
                &-mobile {
                    display: block !important;
                }
            }
        }
    }
</style>
