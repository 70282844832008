<template>
    <div class="timeline-mobile">
        <div class="home-procedure-roadmap-title">
            {{ $t(roadmapTitle) }}
        </div>
        <CommonComponentsTimeLine>
            <template #content>
                <div
                    v-for="(roadmapItem, index) in roadmapData"
                    :key="index"
                    :class="{
                        'procedure-timeline-item': true,
                        'procedure-timeline-item-active': index === curTimelineIndex,
                    }"
                >
                    <div class="procedure-timeline-title">
                        {{ $t(roadmapItem.title) }}
                    </div>
                    <div v-if="$t(roadmapItem.title) !== $t(roadmapItem.subtitle)" class="procedure-timeline-subtitle">
                        {{ $t(roadmapItem.subtitle) }}
                    </div>
                    <div v-if="roadmapItem.mediaPath" class="procedure-timeline-media">
                        <CommonComponentsVideoPlayer
                            class="home-roadmap-mobile-video-dom"
                            :sources="[
                                {
                                    src: roadmapItem.mediaPath,
                                    type: 'video/mp4',
                                },
                            ]"
                            :poster="roadmapItem.poster"
                        />
                    </div>
                </div>
            </template>
        </CommonComponentsTimeLine>
    </div>
</template>

<script lang="ts" setup>
    import { addSlideInUp } from '~~/composables/useScrollTrigger';
    const curTimelineIndex = ref(0);
    defineProps<{
        roadmapTitle: string;
        roadmapData: Array<{
            title: string;
            subtitle: string;
            mediaPath?: string;
            poster?: string;
        }>;
    }>();
    // addToVideoElmt('.home-roadmap-mobile-video-dom');
    onMounted(() => {
        addSlideInUp('.procedure-timeline-item');
        addSlideInUp('.home-procedure-roadmap-title');
    });
</script>

<style lang="less" scoped>
    .timeline-mobile {
        .home-procedure-roadmap-title {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;

            font-size: .convert(@font-size-lg-ssm) [ @vw-mobile];
        }

        :deep(.default-timeline-wrapper) {
            .default-timeline-progress-container .default-timeline-progress .default-timeline-progress-bar {
                background-color: #fff;
                background: linear-gradient(#0032ff, #ae3df4);
            }
        }

        .procedure-timeline-item {
            font-family: Montserrat, 'HarmonyOS Sans SC';
            display: flex;
            flex-direction: column;
            width: 100%;
            color: #fff;

            &:nth-child(n + 3) .procedure-timeline-right {
                margin-top: .convert(30px) [ @vw-mobile];
            }
            .procedure-timeline-title {
                font-size: .convert(16px) [ @vw-mobile];
            }
            .procedure-timeline-subtitle {
                font-size: .convert(12px) [ @vw-mobile];
                text-transform: capitalize;
            }
            .procedure-timeline-media {
                margin-top: .convert(8px) [ @vw-mobile];
                width: 100%;

                video {
                    height: .convert(170px) [ @vw-mobile];
                    width: .convert(303px) [ @vw-mobile];
                }
            }
        }
    }
</style>
