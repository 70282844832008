<template>
    <div class="home-catalogue-layout">
        <div class="home-catalogue-container">
            <CommonComponentsHomeProductCatalogueItem
                v-for="(cataItem, itemIndex) in catalogueList"
                :key="itemIndex"
                class="home-catalogue"
                :index="itemIndex"
                :cata-item="cataItem"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import { routeMapper } from '~~/composables/useRoutesMapper';
    gsap.registerPlugin(ScrollTrigger);

    defineProps<{
        catalogueList: Array<CataItem>;
    }>();
</script>

<style lang="less" scoped>
    .home-catalogue-layout {
        width: 100%;
        display: flex;
        align-items: center;
        color: #ffffff;
        overflow: hidden;

        .home-catalogue-container {
            display: flex;
            gap: .convert(@flex-gap-lg) [ @vw];
            margin: .convert(60px) [ @vw] 0 .convert(50px) [ @vw];

            flex-wrap: nowrap;
            overflow-x: hidden;

            .home-catalogue {
                flex-shrink: 0;
                overflow-x: hidden;
                &:hover {
                    cursor: pointer;
                }
            }
        }

        @media (max-width: @screen-mobile-width) {
            height: fit-content;

            .home-catalogue-container {
                width: 100%;
                display: flex;
                position: relative;
                gap: 10px;
                overflow-x: scroll;
                overflow-y: hidden;

                &::-webkit-scrollbar {
                    display: none;
                }
            }
        }
    }
</style>
