<template>
    <div class="home-partner-layout">
        <div class="home-partner-title-container">
            <CommonComponentsUnionTitle :union-title="unionTitle" />
        </div>
        <div class="home-partner-logo">
            <picture>
                <source media="(max-width: 750px)" :srcset="isCN ? allMobile : allMobileEn" />
                <img :data-src="isCN ? allPc : allPcEn" class="lazy" alt="" />
            </picture>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import allPc from '@assets/imgs/Home/PartnersLogo/all_pc.png';
    import allPcEn from '@assets/imgs/Home/PartnersLogo/all_pc_en.png';
    import allMobile from '@assets/imgs/Home/PartnersLogo/all_mobile.png';
    import allMobileEn from '@assets/imgs/Home/PartnersLogo/all_mobile_en.png';

    import { addSlideInUp } from '~~/composables/useScrollTrigger.js';
    import { ifCNLocale } from '~~/composables/useLocale.ts';

    const isCN = ifCNLocale();
    const unionTitle: UnionTitle = {
        title: 'global_home_section_title_1',
        subTitle: 'global_home_section_subtitle_1',
    };

    onMounted(() => {
        addSlideInUp('.home-partner-logo');
    });
</script>

<style lang="less" scoped>
    .home-partner-layout {
        width: 100%;
        margin-top: .convert(108px) [ @vw];

        .home-partner-title-container {
            width: 100%;
        }

        .home-partner-logo {
            position: relative;
            margin-top: 60px;
            margin-bottom: 60px;
            min-height: .convert(352px) [ @vw];

            img {
                width: 100%;
            }
        }

        @media (max-width: @screen-mobile-width) {
            margin-top: 40px;

            .home-partner-logo {
                margin-top: 20px;
                margin-bottom: 40px;
                padding: 0;
            }
        }
    }

    :lang(en) {
        .home-partner-title-container {
            width: 80%;
            margin: 0 auto;
        }
    }
</style>
