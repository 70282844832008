<template>
    <button
        ref="buttonNode"
        class="button-container"
        :class="{ disabled: disabled, borderless: borderless }"
        :disabled="disabled"
        @mouseenter="onEnter"
        @mouseleave="onLeave"
    >
        <!-- hover 绝对定位遮罩 -->
        <div ref="mask" class="button-hover-mask">
            <div class="button-text">
                {{ buttonText }}
            </div>
            <div class="button-arrow">
                <CommonComponentsSVGCommonArrow />
            </div>
        </div>
        <!-- normal -->
        <div ref="container" class="button-flex-container">
            <div id="button-text" class="button-text">
                {{ buttonText }}
            </div>
            <!-- <div class="button-arrow">
                <CommonComponentsSVGCommonArrow />
            </div> -->
        </div>
    </button>
</template>

<script lang="ts" setup>
    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import { matchMobile } from '~~/composables/useMatchRules.ts';

    gsap.registerPlugin(ScrollTrigger);
    withDefaults(
        defineProps<{
            buttonText: string;
            disabled?: boolean;
            borderless?: boolean;
        }>(),
        {
            disabled: false,
            borderless: false,
        },
    );

    let tl: gsap.core.Timeline;
    const buttonNode = ref(null);
    const container = ref(null);
    const mask = ref(null);
    const onEnter = () => {
        if (!matchMobile()) {
            tl = gsap
                .timeline({ ease: 'none', delay: 0 })
                .fromTo(
                    [container.value, mask.value],
                    { xPercent: 0 },
                    {
                        xPercent: 100,
                        duration: 0.3,
                        delay: 0.2,
                    },
                )
                .set(buttonNode.value, {
                    borderColor: '#0032ff',
                    backgroundColor: 'transparent',
                });
        }
    };
    const onLeave = () => {
        if (tl) tl.reverse();
    };
</script>

<style lang="less" scoped>
    .button-container {
        background-color: transparent;
        padding: 0;
        position: relative;
        // height: .convert(48px) [ @vw];
        // width: .convert(133px) [ @vw];
        min-width: 94px;
        min-height: 34px;
        border: 1px solid #000;
        background-color: #262626;
        border-radius: .convert(83px) [ @vw];

        font-size: .convert(@font-size-md) [ @vw];

        line-height: 100%;
        letter-spacing: 2.24px;

        overflow: hidden;
        @media (max-width: @screen-mobile-width) {
            font-size: .convert(@font-size-xm) [ @vw-mobile]; //14px
        }
        .button-flex-container {
            width: 100%;
            height: 100%;
            padding: .convert(16px) [ @vw] .convert(36px) [ @vw];
            color: #ffffff;

            display: flex;
            align-items: center;
            justify-content: center;

            .button-text {
                white-space: nowrap;
            }
        }

        &:hover {
            cursor: pointer;
        }

        &.disabled {
            cursor: not-allowed;
            border-color: rgba(255, 255, 255, 0.6) !important;
            .button-flex-container {
                color: rgba(255, 255, 255, 0.6);
                background-color: transparent;
            }
        }

        &.borderless {
            border: none;
        }
    }

    .button-hover-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: .convert(83px) [ @vw];
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .convert(@flex-gap-xs) [ @vw];
        color: #ffffff;

        background: #0032ff;

        top: 0;
        left: 0;
        transform: translate(-100%, 0);

        .button-arrow :deep(.svg) {
            width: .convert(18px) [ @vw];
            stroke: white;
        }
    }

    :lang(en) {
        .button-container {
            letter-spacing: 0px !important;

            .button-flex-container {
                padding: .convert(16px) [ @vw] .convert(24px) [ @vw];
            }
        }
    }
</style>
