<template>
    <div class="home-user-voice-layout">
        <div class="home-user-voice-container pc">
            <CommonComponentsHomeUserVoiceCard
                v-for="(userVoiceItem, itemIndex) in data.userVoiceCardList"
                :key="itemIndex"
                class="home-user-voice-card"
                :user-voice-item="userVoiceItem"
            />
        </div>
        <div class="home-user-voice-container mobile">
            <CommonComponentsHomeUserVoiceMobileCards :user-voice-card-list="data.userVoiceCardList" />
        </div>
    </div>
</template>

<script lang="ts" setup>
    // const { useGSAP }= useNuxtApp();
    // const gsap = useGSAP();
    import ribo from '@assets/imgs/Home/PartnersLogo/ribo.png';
    import anta from '@assets/imgs/Home/PartnersLogo/anta.png';
    import dishang from '@assets/imgs/Home/PartnersLogo/dishang.png';
    import jiaxin from '@assets/imgs/Home/PartnersLogo/jiaxin.png';
    import jiaxin_en from '@assets/imgs/Home/PartnersLogo/jiaxin_en.png';

    import { gsap } from 'gsap';
    import { ScrollTrigger } from 'gsap/all';
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    const isCN = ifCNLocale();
    gsap.registerPlugin(ScrollTrigger);

    // nuxt3实现动态img url需要将图片放到public目录下
    // https://blog.csdn.net/weixin_43160498/article/details/128440353
    const data = reactive<{ userVoiceCardList: Array<UserVoiceItem> }>({
        userVoiceCardList: [
            {
                text: 'global_home_user_voice_text_0',
                logoPath: ribo,
            },
            {
                text: 'global_home_user_voice_text_1',
                logoPath: anta,
            },
            {
                text: 'global_home_user_voice_text_2',
                logoPath: dishang,
            },
            {
                text: 'global_home_user_voice_text_3',
                logoPath: isCN ? jiaxin : jiaxin_en,
            },
        ],
    });

    const resizeLayout = () => {
        const layout = document.querySelector('.home-user-voice-layout') as HTMLElement;
        const child = document.querySelector('.home-user-voice-container.pc') as HTMLElement;
        layout.style.height = `${child.offsetHeight}px`;
    };

    const autoFitLayOutHeight = () => {
        resizeLayout();
        window.addEventListener('resize', () => {
            resizeLayout();
        });
    };

    const matched = ref(false);
    onMounted(() => {
        matched.value = matchMobile();

        if (!matched.value) {
            // PC
            addCardScrollHorizontalUpgrade(
                '.home-user-voice-card',
                '.home-user-voice-layout',
                '.home-user-voice-container',
                '.gsap-pin-target',
            );
            autoFitLayOutHeight();
        }
    });
</script>

<style lang="less" scoped>
    .home-user-voice-layout {
        margin: .convert(90px) [ @vw] 0 .convert(150px) [ @vw] 0;
        width: 100%;
        color: #ffffff;
        overflow: hidden;
        position: relative;
        height: .convert(317px) [ @vw]; //////////////
        .home-user-voice-container.pc {
            display: flex;
            align-items: stretch;
            gap: .convert(20px) [ @vw];

            flex-wrap: nowrap;
            overflow-x: hidden;

            position: absolute;
            left: 0;
            top: 0;

            .home-user-voice-card {
                flex-shrink: 0;
                overflow-x: hidden;
            }
        }
        .home-user-voice-container.mobile {
            display: none;
        }

        @media (max-width: @screen-mobile-width) {
            height: fit-content;
            .home-user-voice-container.pc {
                display: none;
            }

            .home-user-voice-container.mobile {
                display: block;
                height: .convert(283px) [ @vw-mobile];
                // width: 100%;
                // display: flex;
                // position: relative;
                // gap: 10px;
                // overflow-x: scroll;
                // overflow-y: hidden;
                // scroll-snap-type: x proximity;

                // &::-webkit-scrollbar {
                //     display: none;
                // }
            }
        }
    }
</style>
