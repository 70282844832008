<template>
    <div class="comp-olympic-banner">
        <video
            class="banner"
            :src="olympicBannerVideo"
            muted
            loop
            playsinline
            autoplay
            preload="auto"
            @click="onSubmit"
        ></video>
        <div class="overlay">
            <div class="text-button">
                <div class="olympic-text">
                    <img :src="imageSrc" />
                </div>
                <CommonComponentsButtonLine
                    v-if="showButton"
                    transparent
                    light
                    :button-text="t('global_link_button_0')"
                    @click="onSubmit"
                    class="olympic-button"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
    import olympicBannerMobileCn from '@assets/imgs/Home/olympicBannerMobileCn.png';
    import olympicBannerMobileEn from '@assets/imgs/Home/olympicBannerMobileEn.png';
    import olympicBannerPcCn from '@assets/imgs/Home/olympicBannerPcCn.png';
    import olympicBannerPcEn from '@assets/imgs/Home/olympicBannerPcEn.png';
    import olympicBannerVideo from '@assets/imgs/AboutUs/Olympic/olympicBannerVideo.mp4';
    import olympicCnPc from '@assets/imgs/AboutUs/Olympic/olympicCnPc.png';
    import olympicEnPc from '@assets/imgs/AboutUs/Olympic/olympicEnPc.png';
    import olympicCnMobile from '@assets/imgs/AboutUs/Olympic/olympicCnMobile.png';
    import olympicEnMobile from '@assets/imgs/AboutUs/Olympic/olympicEnMobile.png';

    import { ref, reactive, onMounted } from 'vue';
    import i18n from '@/locales';
    const { t } = i18n.global;
    import { matchMobile } from '~~/composables/useMatchRules.ts';
    const isMobile = ref(false);
    import { ifCNLocale } from '~~/composables/useLocale.ts';
    const isCN = ifCNLocale();
    // const imageSrc = isCN ? (isMobile ? olympicCnMobile : olympicCnPc) : isMobile ? olympicEnMobile : olympicEnPc;
    // const imageSrc = isMobile ? (isCN ? olympicCnMobile : olympicEnMobile) : isCN ? olympicCnPc : olympicEnPc;
    const imageSrcMap = {
        ['pc']: {
            'zh-CN': olympicCnPc,
            en: olympicEnPc,
        },
        ['mobile']: {
            'zh-CN': olympicCnMobile,
            en: olympicEnMobile,
        },
    };
    const bannerImgMap = {
        ['pc']: {
            'zh-CN': olympicBannerPcCn,
            en: olympicBannerPcEn,
        },
        ['mobile']: {
            'zh-CN': olympicBannerMobileCn,
            en: olympicBannerMobileEn,
        },
    };
    const bannerImg = ref('');
    const imageSrc = ref('');
    const showButton = ref(false);
    onMounted(() => {
        isMobile.value = matchMobile();
        bannerImg.value = bannerImgMap[isMobile.value ? 'mobile' : 'pc'][isCN ? 'zh-CN' : 'en'];
        imageSrc.value = imageSrcMap[isMobile.value ? 'mobile' : 'pc'][isCN ? 'zh-CN' : 'en'];
        setTimeout(() => {
            showButton.value = true;
        }, 500);
    });

    const onSubmit = () => {
        location.href = '/resource/olympic';
    };
</script>

<style lang="less" scoped>
    .comp-olympic-banner {
        position: relative;
        &.hide {
            opacity: 0;
        }
        .banner {
            object-fit: cover;
            width: 100%;
            height: 100vh;
        }
        .button-container {
            position: absolute;
        }
        .overlay {
            position: absolute;
            top: 1px;
            width: 100%;
            height: 100vh;
            background-color: #0000001a;
            z-index: 1;
        }
        .text-button {
            z-index: 2;
            position: relative;
            margin-left: 12%;
            top: 32%;
            .olympic-text img {
                margin-bottom: 63px;
                width: 873px;
                height: 243px;
            }
            .olympic-button {
                font-family: 'HarmonyOS Sans SC';
                font-size: 16px;
                font-weight: 500;
                line-height: 26px;
                color: black;
                background-color: white;
            }
        }
        @media (max-width: @screen-mobile-width) {
            .button-container {
                min-width: 71px;
                min-height: 23px;
            }
            .text-button {
                top: 40%;
                .olympic-text img {
                    margin-bottom: 35px;
                    width: 315px;
                    height: 139px;
                }
                .olympic-button {
                    width: 71px;
                    height: 23px;
                    font-family: 'HarmonyOS Sans SC';
                    font-size: 9.33px;
                    font-weight: 400;
                    line-height: 11.2px;
                }
            }
        }
    }
</style>
