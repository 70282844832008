<template>
    <div class="catalogue-card-conintainer">
        <div class="catalogue-content">
            <!-- <div class="catalogue-card">
                <div class="catalogue-card-key">
                    {{ formatedKey }}
                </div>
                <div>
                    <div class="catalogue-card-category">
                        {{ $t(cataItem.categoryText) }}
                    </div>
                    <div class="catalogue-card-name" v-html="formatedCataName" />
                </div>
                <div class="catalogue-card-arrow">
                    <CommonComponentsSVGCommonCircleArrow />
                </div>
            </div> -->
            <CommonComponentsCardTransform class="catalogue-card" @click="pushRouter(cataItem.to)">
                <template #transform-card-title>
                    <div class="catalogue-card-category">{{ $t(cataItem.categoryText) }}</div>
                    <div class="catalogue-card-name">{{ $t(cataItem.itemName) }}</div>
                </template>
                <template #transform-card-content>
                    <div class="catalogue-card-key">
                        {{ formatedKey }}
                    </div>
                </template>
                <template #transform-card-follow>
                    <div class="catalogue-card-follow">{{ $t(cataItem.itemDes) }}</div>
                </template>
            </CommonComponentsCardTransform>
            <!-- <CommonComponentsCardSlide class="catalogue-card-container">
                <template #slide-card-mask>
                    <div class="catalogue-card-mask">
                        <div class="catalogue-card-mask-name" v-html="formatedCataName"></div>
                        <div class="catalogue-card-mask-arrow">
                            <CommonComponentsSVGCommonCircleArrow />
                        </div>
                    </div>
                </template>
                <template #slide-card-content>
                    <div class="catalogue-card">
                        <div class="catalogue-card-key">
                            {{ formatedKey }}
                        </div>
                        <div>
                            <div class="catalogue-card-category">
                                {{ $t(cataItem.categoryText) }}
                            </div>
                            <div class="catalogue-card-name" v-html="formatedCataName"></div>
                        </div>
                    </div>
                </template>
            </CommonComponentsCardSlide> -->
            <div class="catalogue-des">
                {{ $t(cataItem.itemNature) }}
            </div>
        </div>
        <div class="catalogue-link">
            <CommonComponentsButtonDefault
                borderless
                :button-text="cataItem.linkButtonText"
                @click="pushRouter(cataItem.to)"
            />
        </div>
    </div>
</template>

<script lang="ts" setup>
    import { useI18n } from 'vue-i18n';
    import { pushRouter } from '~~/composables/useRouterController';
    const { t } = useI18n();

    const props = defineProps<{
        cataItem: CataItem;
        index: number;
    }>();

    const formatedKey = computed(() => `0${props.index + 1}`);
</script>

<style lang="less" scoped>
    .space-between() {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .catalogue-card-conintainer {
        font-family: Montserrat, 'HarmonyOS Sans SC';
        .space-between();
        gap: 28px;
        width: .convert(325px) [ @vw];

        font-size: .convert(@font-size-md) [ @vw];
        color: #ffffffff;
        @media (max-width: @screen-mobile-width) {
            width: .convert(131px) [ @vw-mobile];
        }
        .catalogue-card {
            height: .convert(400px) [ @vw];
            width: 100%;
            padding: .convert(14px) [ @vw];

            @media (max-width: @screen-mobile-width) {
                height: .convert(160px) [ @vw-mobile] !important;
                padding: .convert(10px) [ @vw-mobile];
            }

            &-key {
                font-family: NeuePowerW05-Bold;
                margin-bottom: .convert(48px) [ @vw];

                font-weight: @font-weight-xl;
                font-size: .convert(@font-size-title-xxl) [ @vw];
                font-style: normal;
                font-weight: 700;
                line-height: @line-height-xs-sm;

                @media (max-width: @screen-mobile-width) {
                    font-size: .convert(@font-size-title) [ @vw-mobile];
                }
            }

            &-category {
                margin: .convert(6px) [ @vw] 0;
                font-style: normal;
                font-size: .convert(@font-size-xl) [ @vw];
                font-weight: 500;
                line-height: normal;
                @media (max-width: @screen-mobile-width) {
                    font-size: .convert(@font-size-xm-xm) [ @vw-mobile];
                }
            }

            &-follow {
                font-family: 'HarmonyOS Sans SC';
            }

            &-name {
                font-weight: 500;
                font-size: .convert(60px) [ @vw];
                line-height: normal;
                font-style: normal;
                text-transform: capitalize;
                @media (max-width: @screen-mobile-width) {
                    font-size: .convert(@font-size-xl) [ @vw-mobile];
                }
            }
        }
        .catalogue-des {
            font-family: 'HarmonyOS Sans SC';
            margin: .convert(28px) [ @vw] 0 0 .convert(20px) [ @vw];
            @media (max-width: @screen-mobile-width) {
                display: none;
            }
        }

        .catalogue-link {
            @media (max-width: @screen-mobile-width) {
                display: none;
            }
        }
    }
</style>
