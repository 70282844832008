<template>
    <div class="scroll-cards" @touchend="end" @touchstart="start">
        <div class="mobile-cards">
            <CommonComponentsHomeSolutionsCard
                v-for="(solutionCard, index) in solutionsCards"
                :key="index"
                class="mobile-cards-item"
                :solution-card="solutionCard"
                :style="data.cardStyle[index].card"
                :text-visible="data.cardStyle[index].text"
                @click="() => pushRouter(solutionCard.path)"
            />
        </div>
    </div>
</template>
<script lang="ts" setup>
    import { reactive, onBeforeMount } from 'vue';
    const props = defineProps<{
        solutionsCards: Array<SolutionCard>;
    }>();
    const data = reactive({
        loading: true,
        currentIndex: 1,
        centerInfo: '',
        startX: '',
        startY: '',
        endX: '',
        previous: 0,
        cardStyle: [
            {
                card: {
                    id: 'center',
                    opacity: 1,
                    zIndex: 4,
                    transition: '.4s',
                },
                text: {
                    opacity: 1,
                },
            },
            {
                card: {
                    id: 'right',
                    opacity: 1,
                    zIndex: 1,
                    filter: 'blur(3px)',
                    // transform: 'translate3d(40px, 0px, -100px)',
                    'transform-origin': 'center',
                    scale: 0.9,
                    transform: 'translateX(15%)',
                    '-webkit-transform': 'translateX(15%)',
                    '-moz-transform': 'translateX(15%)',
                    '-ms-transform': 'translateX(15%)',
                    '-o-transform': 'translateX(15%)',
                    transition: '.4s',
                },
                text: {
                    opacity: 0,
                },
            },
            {
                card: {
                    id: 'left',
                    opacity: 1,
                    zIndex: 1,
                    filter: 'blur(3px)',
                    // transform: 'translate3d(-40px, 0px, -100px)',
                    'transform-origin': 'center',
                    scale: 0.9,
                    transform: 'translateX(-15%)',
                    '-webkit-transform': 'translateX(-15%)',
                    '-moz-transform': 'translateX(-15%)',
                    '-ms-transform': 'translateX(-15%)',
                    '-o-transform': 'translateX(-15%)',
                    transition: '.4s',
                },
                text: {
                    opacity: 0,
                },
            },
            {
                card: {
                    id: 'left',
                    opacity: 1,
                    zIndex: 1,
                    filter: 'blur(3px)',
                    // transform: 'translate3d(-40px, 0px, -100px)',
                    'transform-origin': 'center',
                    scale: 0.9,
                    transform: 'translateX(-15%)',
                    '-webkit-transform': 'translateX(-15%)',
                    '-moz-transform': 'translateX(-15%)',
                    '-ms-transform': 'translateX(-15%)',
                    '-o-transform': 'translateX(-15%)',
                    transition: '.4s',
                },
                text: {
                    opacity: 0,
                },
            },
        ],
    });

    const { $bus } = useNuxtApp();
    const showModal = () => {
        $bus.$emit('show:applyModal');
    };

    // 滑动上一个
    const prev = () => {
        data.cardStyle.push(data.cardStyle.shift());
        data.currentIndex = data.currentIndex - 1;
        if (data.currentIndex < 0) {
            data.currentIndex = props.solutionsCards.length - 1;
        }
    };
    // 滑动下一个
    const next = () => {
        data.cardStyle.unshift(data.cardStyle.pop());
        data.currentIndex = data.currentIndex + 1;
        if (data.currentIndex > props.solutionsCards.length - 1) {
            data.currentIndex = 0;
        }
    };
    // 开始移动端滑动屏幕
    const start = (event) => {
        data.startX = event.changedTouches[0].clientX;
        data.startY = event.changedTouches[0].clientY;
    };
    // 连续滑动 @touchmove="move"
    const move = (event) => {
        // data.endY = event.changedTouches[0].clientY;
        // data.endX = event.changedTouches[0].clientX;
        // stopDefault(event);
        // data.interval = data.endX - data.startX;
        // if (data.interval > 40) {
        //     data.startX = data.endX;
        //     data.prev();
        // }
        // if (data.interval < -40) {
        //     data.startX = data.endX;
        //     data.next();
        // }
    };
    // 滑动
    const end = (event) => {
        data.endY = event.changedTouches[0].clientY;
        data.endX = event.changedTouches[0].clientX;
        formatSwiper();
    };
    const formatSwiper = () => {
        if (data.startX > data.endX + 40 && Math.abs(data.startY - data.endY) < 60) {
            next();
        } else if (data.endX > data.startX + 40 && Math.abs(data.startY - data.endY) < 60) {
            prev();
        }
    };
    // 阻止touchmove的横向默认事件（ios快捷操作会关闭页面）
    // const stopDefault = (event) => {
    //     const differenceY = data.endY - data.startY;
    //     const differenceX = data.endX - data.startX;
    //     if (Math.abs(differenceX) > Math.abs(differenceY)) {
    //         event.preventDefault();
    //     }
    // };

    // const addCardStyle = () => {
    //     console.log('add223e');

    //     if (props.solutionsCards.length > 3) {
    //         let addtime = props.solutionsCards.length - 3;
    //         for (let i = 0; i < addtime; i++) {
    //             console.log('add');
    //             data.cardStyle.push({
    //                 card: {
    //                     id: 'left',
    //                     opacity: 1,
    //                     zIndex: 1,
    //                     filter: 'blur(3px)',
    //                     // transform: 'translate3d(-40px, 0px, -100px)',
    //                     'transform-origin': 'center',
    //                     scale: 0.9,
    //                     transform: 'translateX(-15%)',
    //                     '-webkit-transform': 'translateX(-15%)',
    //                     '-moz-transform': 'translateX(-15%)',
    //                     '-ms-transform': 'translateX(-15%)',
    //                     '-o-transform': 'translateX(-15%)',
    //                     transition: '.4s',
    //                 },
    //                 text: {
    //                     opacity: 0,
    //                 },
    //             });
    //         }
    //     }
    // };

    // onBeforeMount(() => {
    //     addCardStyle(); // 加入样式位置的index
    // });
</script>

<style lang="less" scoped>
    .scroll-cards {
        width: 100%;
        height: 100%;
        .mobile-cards {
            height: 100%;
            width: 100%;
        }
    }
</style>
